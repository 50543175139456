import React from 'react';
import Card from "../ui/card/card";
import Column from "../ui/column/column";
import {SiteMethods} from "../methods/site.methods";
import {useLocation} from "react-router-dom";
import {observer} from "mobx-react-lite"
const CartRoomComponent = ({loadingData}) => {
    const location = useLocation()
    const [datas, setDatas] = React.useState([]);

    const getDatas = React.useCallback(() => {
        SiteMethods.getUnits(localStorage.getItem('lang')).then(data => {
            setDatas(() => Object.values(data.data))
            console.log('eee', Object.values(data.data))
        })
    }, []);

    React.useEffect(() => {
        getDatas();
    }, [location.pathname]);

    return (
        loadingData &&
        datas.filter(item => Number(item.unitTypeId) === Number(loadingData)).map((room) => {
            if (room.isActive) {
                return (
                    <Column className={'w50'} col={4}>
                        <Card
                            url={room.url + localStorage.getItem('lang')}
                            target={'_blank'}
                            key={room.id}
                            size={room.size_text}
                            size2={room.size_number}
                            img={
                                room.resId
                                ?
                                    process.env.REACT_APP_STATIC_ROUTES + `units/${room.resId}/` + room.img
                                :
                                    process.env.REACT_APP_STATIC_ROUTES + `units/${room.id}/` + room.img
                            }
                            price={room.price}
                            description={room.text}
                        />
                    </Column>
                )
            }
        })
    );
};

export default observer(CartRoomComponent);