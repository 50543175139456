import React from 'react';
import HeaderComponent from "../components/header.component";
import BannerComponent from "../components/banner.component";
import FooterComponent from "../components/footer.component";
import MapComponent from "../components/map.component";
import StorageComponent from "../components/storage.component";
import CatalogComponent from "../components/catalog.component";
import HelpComponent from "../components/help.component";
import HomeNewsComponent from "../components/homeNews.component";
import {useScroll} from "../hooks/useScroll";
import {useScrollToTop} from "../hooks/useScrollToTop";
import {observer} from "mobx-react-lite";

const HomePage = () => {
    const [Element, scrollToElement] = useScroll();

    useScrollToTop();

    return (
        <>
            <Element name="header">
                <HeaderComponent toScroll={scrollToElement}  />
            </Element>
            <Element name="banner">
                <BannerComponent/>
            </Element>
            <Element name="slider">
                <StorageComponent />
            </Element>
            <Element name="catalog">
                <CatalogComponent />
            </Element>
            <Element name="help">
                <HelpComponent />
            </Element>
            <Element name="map">
                <MapComponent  />
            </Element>
            <HomeNewsComponent />
            <FooterComponent toScroll={scrollToElement} />
        </>
);
};

export default observer(HomePage);