import React from 'react';
import c from './video.module.sass';

const Video = ({controls, autoPlay, poster, muted, loop, type, src}) => {
    return (
        <video
            className={c.video}
            controls={controls}
            poster={poster}
            autoPlay={autoPlay}
            muted={muted} loop={loop}
        >
            <source
                src={src}
                type={type}
            />
        </video>
    );
};

export default Video;