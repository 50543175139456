import React from 'react';
import c from './select.module.sass';
import CustomLink from "../customLink/customLink";

const Select = ({icon, position, items, placeholder, value, defaultValue, onChange, url}) => {

    const [open, setOpen] = React.useState(false);
    const selectRef = React.useRef(null);

    // const filterItems = (arr, value) => {
    //     return  arr.filter((a) => String(a.value).toLowerCase().trim().includes(value.toLowerCase().trim()));
    // }

    const handleOpen = ()  =>  {
        setOpen(() => !open);
    };

    React.useEffect(()  =>  {
        const onClick = e => selectRef.current.contains(e.target) || setOpen(() => false);
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        setOpen(() => false)
    }, [value])

    console.log(items)

    return (
        items.length > 0
        &&
        <div ref={selectRef} className={`${c.select} ${position === 'right' ? c.right : ''}`}>
            <div onClick={handleOpen} className={`${c.select_container} ${open ? c.select_open : ''}`}>
                {
                    icon && <img className={`${c.select_icon}`} src={icon} alt={'select icon'}/>
                }
                {
                    !defaultValue
                    ?
                    value
                        ? value
                        : placeholder
                    :
                        defaultValue
                }
                {
                    items.length > 1
                    &&
                    <img src={process.env.PUBLIC_URL + '/images/chevron.svg'} alt={'select chevron'}/>
                }
            </div>
            {
                items.length > 1
                &&
                <div className={`${c.select_items} ${open ? c.select_items_open : ''}`}>
                    {
                        items.map(item => (

                            items.length === 1
                                ?
                                <div onClick={() => onChange(item.id)} key={item.id} className={c.select_item}
                                     data-value={item.name + ' ' + item.address}>{item.name + ' - ' + item.address}</div>
                                :
                                <CustomLink style={{textDecoration: 'none'}} to={item.url} target="_blank" key={item.id}
                                            className={c.select_item}
                                            data-value={item.name + '' + item.address}>{item.name + '-' + item.address}</CustomLink>
                        ))
                    }
                </div>
            }

        </div>
    );
};

export default Select;