import React from 'react';
import Select from "../ui/select/select";
import {SiteMethods} from "../methods/site.methods";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import {useLocation} from "react-router-dom";

const LocationComponent = () => {
    const {stock} = React.useContext(Context);
    const [stocks, setStocks] = React.useState({});
    const location = useLocation();

    const loadingStock = React.useCallback(() => {
        SiteMethods.getStocks().then((data) => {
            setStocks(() => data.data);
            localStorage.setItem('stocks', JSON.stringify(data.data));
            stock.setStock(data.data);
        })
    }, []);

    React.useEffect(() => {
        loadingStock();
    }, [location.pathname]);

    const [value, setValue] = React.useState('');
    const [, setValueId] = React.useState('');
    const [defaultValue, setDefaultValue] = React.useState(null);
    const [valueUrl, setValueUrl] = React.useState(null);

    const handleChange = (e) => {
        setValue(() => `${Object.values(stock.isStock).filter(a => a.id === e)[0].name} - ${Object.values(stock.isStock).filter(a => a.id === e)[0].address}`);
        setValueId(() => e);
        setDefaultValue(() => null);
        setValueUrl(() => Object.values(stock.isStock).filter(a => a.id === e)[0].url);
    }

    React.useEffect(() => {
        setDefaultValue(() => `${Object.values(stock.isStock)[0]?.name} - ${Object.values(stock.isStock)[0]?.address}`);
    }, [stock.isStock]);

    return (
        <Select
            position={'right'}
            defaultValue={defaultValue}
            items={
                Object.values(stocks)
            }
            onChange={handleChange}
            value={value}
            placeholder={'Выберите склад'}
            icon={process.env.PUBLIC_URL  +  "/images/location.svg"}
            url={valueUrl}
        />
    );
};

export default observer(LocationComponent);