import React from 'react';
import GoogleMapReact from "google-map-react";
import Container from "../ui/container/container";
import Module from "../ui/module/module";
import Section from "../ui/section/section";
import Column from "../ui/column/column";
import T from "./t.component";
import CustomLink from "../ui/customLink/customLink";
import CustomImg from "../ui/customImg/customImg";
import {observer} from "mobx-react-lite";
import {Context} from "../index";
import {useLocation} from "react-router-dom";
import MY_ICON_MARKER from '../i/i.svg'

const defaultProps = {
    center: {
        lat: 56.91370236167376,
        lng: 24.1410897448897
    },
    zoom: 11
};

const createMapOptions = (maps) => {
    return {
        panControl: false,
        mapTypeControl: false,
        scrollwheel: false,
        styles: [{ stylers: [{ 'saturation': -100 }, { 'gamma': 0.8 }, { 'lightness': 4 }, { 'visibility': 'on' }] }]
    }
}

const Marker = ({ icon , title, address, url, image}) => {
    const [popup, setPopup] = React.useState(false);

    const togglePopup = () => {
        setPopup(!popup);
    }

    const markerRef = React.useRef(null);

    React.useEffect(()  =>  {
        const onClick = e => markerRef.current.contains(e.target) || setPopup(() => false);
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
        // eslint-disable-next-line
    }, []);

    return (
        <div ref={markerRef}>
            <CustomImg className={popup ? 'activeMarker' : ''} onClick={togglePopup} width={48} height={48} src={icon} alt={"marker"}/>
            {
                popup && <div style={{position: 'absolute', zIndex: '100', top: '0', left: '12rem', transform: 'translateX(-50%)'}}>
                    <Module variant={'dark'}>
                        <h3>{title}</h3>
                        <CustomImg style={{objectFit: 'contain'}} width={240} height={140} src={image} alt={title}/>
                        <p style={{marginBottom: 0, paddingBottom: 0}}>{address}</p>
                        <CustomLink style={{fontSize: '.8rem', marginTop: 0, paddingTop: '1rem'}} to={url} target={'_blank'}>
                            <T>Перейти на сайт хранилища</T>
                            <CustomImg src={`${process.env.PUBLIC_URL}/images/arrow_up_orange.svg`} alt={title} />
                        </CustomLink>
                    </Module>
                </div>
            }
        </div>
    )
}

const MyMarker = () => {
    return (
        <div style={{display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: 'center'}}>
            <CustomImg width={48} height={48} src={MY_ICON_MARKER} alt={"marker"}/>
            <p style={{display: 'block', marginTop: '0', padding: '.2rem .5rem', background: "#fff", fontSize: '.8rem', fontWeight: 900, textAlign: 'center'}}><T>Я</T></p>
        </div>
    )
}

const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
};

const MapComponent = () => {
    const {stock} = React.useContext(Context);
    const location = useLocation();
    const [coord, setCoord] = React.useState({lat: 0, lng: 0})

    React.useEffect(() => {
        window.navigator.geolocation.watchPosition((position) => {
            const obj = {};
            obj.lat = position.coords.latitude;
            obj.lng = position.coords.longitude
            setCoord(() => obj)
        }, (error) => {
            console.log(error)
        }, options)
    }, [])

    const [lang, setLang] = React.useState('');

    React.useEffect(() => {
        setLang(() => localStorage.getItem('lang'))
    }, [location.pathname])

    return (
        <>
            <Container name={'map'}>
                <Section margin={true} direction={'vertical'}>
                    <Column col={9}>
                        <h2><T>локации в риге</T> - <span>{Object.keys(stock.isStock).length}</span></h2>
                    </Column>
                </Section>
            </Container>

            <Container width={true}>
                <div style={{height: '35rem', width: '100%'}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
                            language: lang ? lang : 'lv',
                            region: lang ? lang : 'lv',
                        }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                        layerTypes={['TrafficLayer', 'TransitLayer']}
                        options={createMapOptions}
                    >
                        {
                            Object.values(stock.isStock).map((marker, index) => {
                                if (marker.isActive) {
                                    return (
                                        <Marker
                                            key={marker.id}
                                            lat={marker.lat}
                                            lng={marker.lng}
                                            icon={`${process.env.PUBLIC_URL}/images/marker.svg`}
                                            title={marker.name}
                                            address={marker.address}
                                            image={process.env.REACT_APP_STATIC_ROUTES + `stock/${marker.id}/` + marker.img}
                                            url={marker.url}
                                        />
                                    )
                                }
                            })
                        }
                        <MyMarker {...coord} />
                    </GoogleMapReact>
                </div>
            </Container>
        </>
    );
};

export default observer(MapComponent);