import React from 'react';
import Section from "../ui/section/section";
import Column from "../ui/column/column";
import Line from "../ui/line/line";
import Button from "../ui/button/button";
import CartRoomComponent from "./cartRoom.component";
import Container from "../ui/container/container";
import T from "./t.component";
import {SiteMethods} from "../methods/site.methods";
import {useLocation} from "react-router-dom";
import {observer} from 'mobx-react-lite';

const CatalogComponent = () => {
    const location = useLocation()
    const [datas, setDatas] = React.useState({});
    const [defaultUT, setDefaultUT] = React.useState("");

    const getDatas = React.useCallback(() => {
        SiteMethods.getUnitTypes(localStorage.getItem('lang')).then(data => {
            setDatas(() => data.data);
        });
    }, []);

    React.useEffect(() => {
        getDatas();
    }, [location.pathname]);

    React.useEffect(() => {
        if (!localStorage.getItem('defaultUnit')){
            if (Object.values(datas)[0]?.resId) {
                setDefaultUT(() => Object.values(datas)[0]?.resId);
            } else {
                setDefaultUT(() => Object.values(datas)[0]?.id);
            }
        } else {
            setDefaultUT(() => localStorage.getItem('defaultUnit'));
        }
    }, [datas]);

    const toggleUnitType = (id) => {
        localStorage.setItem('defaultUnit', id);
        setDefaultUT(() => id);
    }

    return (
        <Container name={'catalog'}>
            <Section margin={true} direction={'vertical'}>
                <Column col={12}>
                    <Line />
                </Column>
                <Section>
                    <Column className={'w100'} col={7}>
                        <h2><T>Выберите подходящую кладовку</T></h2>
                    </Column>
                    <Column className={'w100 mb2'} col={5}>
                        <div className={'filter'} style={{display: 'flex', alignItems: 'center', gap: '1rem', justifyContent:'flex-end'}}>
                            {
                                Object.keys(datas).length > 0
                                &&
                                <>
                                    <p style={{marginTop: 0}}><T>Фильтровать по</T>:</p>
                                    {
                                        Object.values(datas).map(data => {
                                            if (data.isActive) {
                                                return (
                                                    <Button
                                                        variant={
                                                            data.resId
                                                                ?
                                                                Number(defaultUT) === Number(data.resId) ? 'fill' : 'border'
                                                                :
                                                                Number(defaultUT) === Number(data.id) ? 'fill' : 'border'
                                                        }
                                                        onClick={data.resId ? () => toggleUnitType(data.resId) : () => toggleUnitType(data.id)}
                                                    >
                                                        {data.name}
                                                    </Button>
                                                );
                                            }
                                        })
                                    }
                                </>
                            }
                        </div>
                    </Column>
                </Section>
            </Section>
            <Section>
                <CartRoomComponent loadingData={defaultUT} />
            </Section>
        </Container>
    );
};

export default observer(CatalogComponent);