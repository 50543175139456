import React from 'react';
import Section from "../ui/section/section";
import Column from "../ui/column/column";
import Line from "../ui/line/line";
import Button from "../ui/button/button";
import Card from "../ui/card/card";
import CardSmallComponent from "./cardSmall.component";
import Container from "../ui/container/container";
import {SiteMethods} from "../methods/site.methods";
import T from "./t.component";
import moment from "moment";
import {useLocation} from "react-router-dom";

const HomeNewsComponent = () => {
    const [datas, setDatas] = React.useState({});
    const location = useLocation();
    const [lastNews, setLastNews] = React.useState({});
    const [last3News, setLast3News] = React.useState([]);

    const getDatas = React.useCallback(() => {
        SiteMethods.getNews(localStorage.getItem('lang')).then(data => {
            setDatas(() => data.data);
        });
    }, []);

    React.useEffect(() => {
        getDatas();
    }, [location.pathname]);

    React.useEffect(() => {
        setLastNews(() => Object.values(datas).toSorted((a, b) => a?.id - b?.id).slice(-1)[0]);
        setLast3News(() => Object.values(datas).toSorted((a, b) => a?.id - b?.id).slice(-4, -1));
    }, [datas]);

    return (
        <Container>
            <Section margin={true}>
                <Column col={12}>
                    <Line style={{marginTop: 0}} />
                </Column>
                <Column className={'w100 mb2 mob'} col={4}>
                    <h2><T>Новости</T></h2>
                    <Button url={'/news'} variant={'border_link'}><T>Читать все</T></Button>
                </Column>
                <Column className={'w50'} col={4}>
                    <Card
                        variant={'news'}
                        url={`/news/${lastNews?.slug}`}
                        date={moment(Date(lastNews?.createdAt)).format('MMMM DD, YYYY')}
                        title={lastNews?.name}
                        description={lastNews?.description}
                        img={lastNews?.resId
                            ?
                            process.env.REACT_APP_STATIC_ROUTES + `news/${lastNews?.resId}/` + lastNews?.img
                            :
                            process.env.REACT_APP_STATIC_ROUTES + `news/${lastNews?.id}/` + lastNews?.img
                        }
                    />
                </Column>
                <CardSmallComponent news={last3News}/>
            </Section>
        </Container>
    );
};

export default HomeNewsComponent;